import styled from 'styled-components';

export const ContactInfoContainer = styled.div`
  margin-top: 60px;
`;

export const SocialMediaHeadingContainer = styled.div`
  @media screen and (min-width: 600px) {
    margin-top: 40px;
  }
`;
