import React from 'react';
import { PageProps, graphql } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';

import { Shell } from '@components/Shell/Shell';
import nl2br from 'react-nl2br';
import { useWhichLanguage } from '@src/hooks/useWhichLanguage';
import { InfoSection } from '@src/components/InfoSection/InfoSection';
import { SEO } from '@src/components/SEO/SEO';
import { Content } from '@src/components/Content/Content';
import * as S from '../styles/contact.S';
import { Heading, Heading2 } from '@src/components/Heading/Heading';

const ContactPage: React.FC<
  PageProps & {
    data: {
      api: {
        contact: {
          id: number;
          email: string;
          phone: string;
          imagePath: string;
          imagePathSharp: {
            childImageSharp: { gatsbyImageData: IGatsbyImageData };
          };
          svText: string;
          enText: string;
          enHtmlTitle: string;
          svHtmlTitle: string;
          enHtmlDescription: string;
          svHtmlDescription: string;
        } | null;
      };
      site: {
        siteMetadata: {
          siteUrl: string;
        };
      };
    };
  }
> = ({
  data: {
    api: { contact },
    site: {
      siteMetadata: { siteUrl },
    },
  },
  location,
}) => {
  const { t } = useI18next();
  const { isEnglish } = useWhichLanguage();

  if (!contact) {
    return null;
  }

  const {
    email,
    phone,
    imagePathSharp,
    svText,
    enText,
    enHtmlTitle,
    svHtmlTitle,
    enHtmlDescription,
    svHtmlDescription,
  } = contact;

  const image = getImage(imagePathSharp);

  return (
    <Shell>
      <SEO
        locationOrigin={siteUrl}
        locationPathname={location.pathname}
        openGraphImagePath={
          imagePathSharp.childImageSharp.gatsbyImageData.images.fallback?.src
        }
        openGraphImageWidth={
          imagePathSharp.childImageSharp.gatsbyImageData.width
        }
        openGraphImageHeight={
          imagePathSharp.childImageSharp.gatsbyImageData.height
        }
        title={isEnglish ? enHtmlTitle : svHtmlTitle}
        description={isEnglish ? enHtmlDescription : svHtmlDescription}
      />
      <Content>
        <Container>
          <Grid container alignItems="center" justifyContent="center">
            <Grid
              container
              spacing={3}
              sm={12}
              md={9}
              item
              justifyContent="space-between"
            >
              <Grid item sm={7}>
                <Heading className="mt0">{t('Contact')}</Heading>
                <p className="mt0">{nl2br(isEnglish ? enText : svText)}</p>
                <S.ContactInfoContainer>
                  <InfoSection title={t('Phone')} icon="mobile">
                    <a href={`tel:${phone}`}>{phone}</a>
                  </InfoSection>
                  <InfoSection title={t('Email')} icon="envelope">
                    {email}
                  </InfoSection>
                </S.ContactInfoContainer>
              </Grid>
              {image && (
                <Grid item sm={5} className="xsHidden">
                  <GatsbyImage
                    image={image}
                    alt=""
                    style={{ borderRadius: '50%' }}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container spacing={3} sm={12} md={9} item>
              <Grid item sm={12}>
                <S.SocialMediaHeadingContainer>
                  <Heading2>{t('SocialMedia')}</Heading2>
                </S.SocialMediaHeadingContainer>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <InfoSection title="Instagram" icon="instagram">
                  <a
                    href="https://www.instagram.com/lenalotten_art/"
                    target="_blank"
                  >
                    @lenalotten_art
                  </a>
                </InfoSection>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <InfoSection title="Facebook" icon="facebook-original">
                  <a
                    href="https://www.facebook.com/lenalottenart"
                    target="_blank"
                  >
                    lenalottenart
                  </a>
                </InfoSection>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <InfoSection title="YouTube" icon="youtube">
                  <a href="https://www.youtube.com/@lenalotten" target="_blank">
                    @lenalotten
                  </a>
                </InfoSection>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <InfoSection title="Twitter" icon="twitter">
                  <a href="https://twitter.com/lenalotten" target="_blank">
                    @lenalotten
                  </a>
                </InfoSection>
              </Grid>
              {image && (
                <Grid item sm={5} className="onlyXsVisible">
                  <GatsbyImage
                    image={image}
                    alt=""
                    style={{ borderRadius: '50%' }}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Container>
      </Content>
    </Shell>
  );
};

export default ContactPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
    api {
      contact {
        id
        email
        phone
        imagePath
        imagePathSharp {
          childImageSharp {
            gatsbyImageData(
              width: 600
              height: 600
              placeholder: NONE
              formats: [AUTO, WEBP, AVIF]
              transformOptions: { fit: COVER, cropFocus: CENTER }
            )
          }
        }
        svText
        enText
        enHtmlTitle
        svHtmlTitle
        enHtmlDescription
        svHtmlDescription
      }
    }
  }
`;
